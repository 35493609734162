import React, { useState, useRef, useEffect, useMemo } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { ToastContainer, toast } from "react-toastify";
import { Form, InputGroup } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Panel from "../../../components/Panel";
import UserInput from "../../../components/Inputs";
import CustomDropdown from "../../../components/Dropdown";
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";
import CustomTextArea from "../../../components/Textarea";
import PrimaryButton from "../../../components/Buttons/Primary";
import CheckBoxInput from "../../../components/Inputs/CheckBox";
import { Container, Row, Col } from "react-bootstrap";
import "./Styles.scss";
import CancelIcon from "../../../assets/images/cancel.png";
import { saveSupplier } from "../../../core/Api/supplier";
import { uploadImage } from "../../../core/Api/staff";
import { getCountryList, getCityByCountryCode } from "../../../core/Api/Settings";
import { getAuthAttribute, setAuthAttribute, getDateOptions, checkActionPermission } from "../../../utils/helper";
import { getBusinessData } from "../../../core/Api/myBusiness";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AssetsImg from "images-col";
import { getEmployees } from "../../../core/Api/employee";
import FullPageSpinner from "../../../components/FullPageSpinner";
import { getProfileCompletionStatus } from "../../../core/Api/partner";
import * as _ from "lodash";
import MapSearch from "../../../components/MapSearch";
import PhoneNumberInput from "../../../components/Inputs/PhoneNumber";
import VerifyMobileNumber from "./VerifyMobile";

const DefaultImage = "https://testapiv2.boon.sa/uploads/supplier_media/default_image.jpg?v=1628698285";

const supplierModel = {
	logo: AssetsImg?.img_default,
	logo_filename: "",
	supplier_id: null,
	supplier_type: "salon",
	settlement_frequency: "",
	commission: 0,
	supplier_name: "",
	arb_supplier_name: "",
	description: "",
	arb_description: "",
	registration_number: "",
	vat_number: "",
	home_amt_min: 0,
	salon_amt_min: 0,
	payout_threshold: "",
	payment_modes: "",
	supplier_status_id: "",
	coming_soon: 1,
	address_id: "",
	location: "",
	supplier_city: "",
	supplier_country: "",
	neighbourhood: "",
	lat_lng: "",
	supplier_rep: null,
	is_active: 1,
	supplier_gender: null,
	auto_confirm_bookings: "0",
	include_in_available_today: "0",
	working_hours: [
		{
			day: "Sunday",
			start_time: null,
			end_time: null,
			isDisabled: false,
		},
		{
			day: "Monday",
			start_time: null,
			end_time: null,
			isDisabled: false,
		},
		{
			day: "Tuesday",
			start_time: null,
			end_time: null,
			isDisabled: false,
		},
		{
			day: "Wednesday",
			start_time: null,
			end_time: null,
			isDisabled: false,
		},
		{
			day: "Thursday",
			start_time: null,
			end_time: null,
			isDisabled: false,
		},
		{
			day: "Friday",
			start_time: null,
			end_time: null,
			isDisabled: false,
		},
		{
			day: "Saturday",
			start_time: null,
			end_time: null,
			isDisabled: false,
		},
	],
};
const AddMyBusiness = (props) => {
	const { t } = useTranslation();
	const { history, hideTitle, match, disabled, switchToEditMode, toggleDisabled, warningMessage, setUpdateProfileStatus } = props;
	const mapRef = useRef(null);
	const simpleValidator = useRef(
		new SimpleReactValidator({
			validators: {
				time: {
					message: t("End time must be greater than Start time"),
					rule: (endTime, params) => {
						if (!endTime || params[0] === "") {
							return false;
						}
						const startTime = params.join(" ", ":").replace(" ", ":");
						return moment(endTime, "LT").isAfter(moment(startTime, "LT"));
					},
					required: true,
				},
				arabic: {
					message: t("Must be arabic"),
					rule: (val) => {
						const isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
						return isArabic.test(val);
					},
					required: true,
				},
				english: {
					message: t("Must be english"),
					rule: (val) => {
						const isEnglish = /[a-zA-Z0-9]/;
						return isEnglish.test(val);
					},
					required: true,
				},
			},
		})
	);
	const [, forceUpdate] = useState();
	const intialModel = _.cloneDeep(supplierModel);
	const [model, setModel] = useState(intialModel);
	const tempModel = useRef(intialModel);
	const userTypeId = getAuthAttribute("user_type_id");
	const isUserAdmin = userTypeId == 4;
	const dateOptions = [...getDateOptions()];
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [countryList, setCountryList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [employees, setEmployees] = useState([{ label: `Select Option`, value: null }]);
	const searchFieldRef = useRef(null);
	const [centerCords, setCenterCords] = useState({
		lat: 24.7132552,
		lng: 46.6786387,
	});
	const [markerCords, setMarkerCords] = useState({
		lat: 24.7132552,
		lng: 46.6786387,
	});
	const [imagePreviewURL, setImagePreviewURL] = useState({
		image: null,
		src: AssetsImg?.img_default,
	});
	const [isLoading, setIsLoading] = useState(false);
	const settelmentOptions = [
		{ label: t("Select Option"), value: null },
		{ label: t("Weekly"), value: "Weekly" },
		{ label: t("Monthly"), value: "Monthly" },
		{ label: t("Yearly"), value: "Yearly" },
	];

	const supplierGenderOptions = [
		{ label: t("Select Option"), value: null },
		{ label: t("Male"), value: "male" },
		{ label: t("Female"), value: "female" },
		{ label: t("Both"), value: "both" },
	];
	const autoConfirmBookings = [
		{ value: "1", label: t("YES") },
		{ value: "0", label: t("No") },
	];
	const [comingSoonStatus, setComingSoonStatus] = useState(1);
	const [profileStats, setProfileStats] = useState(null);
	const [isNewPhoneVerified, setIsNewPhoneVerified] = useState(false);
	const [currPhoneNo, setCurrPhoneNo] = useState(null);
	const [showPartnerMobileModal, setShowPartnerMobileModal] = useState(false);

	const onPlacesChanged = async (result) => {
		if (result) {
			const filteredAddresses = (result.address_components || []).filter(
				(item) => item.types.includes("political") && item.types.includes("sublocality")
			);
			const fullAddress = result.formatted_address;
			const neighbourhood = filteredAddresses[0].long_name;
			const lat = result.geometry.location.lat();
			const lng = result.geometry.location.lng();
			if (!lat || !lng) {
				toast.error("Please enter valid address");
				return;
			}
			setCenterCords({ lat, lng });
			setMarkerCords({ lat, lng });
			setModel({
				...model,
				location: fullAddress,
				neighbourhood,
				lat_lng: [lat, lng].toString(),
			});
		}
	};

	useEffect(() => {
		if (model?.lat_lng.split(",")[1] && model?.lat_lng.split(",")[0]) {
			const lat = Number(model?.lat_lng.length ? model?.lat_lng.split(",")[0] : "");
			const lng = Number(model?.lat_lng.length ? model?.lat_lng.split(",")[1] : "");
			setCenterCords({
				lat,
				lng,
			});
			setMarkerCords({
				lat: lat,
				lng: lng,
			});
		}
		// eslint-disable-next-line
	}, [model?.lat_lng]);

	const commissionOptions = [];
	for (let i = 0; i <= 100; i += 5) {
		commissionOptions.push({ label: `${i}%`, value: i });
	}

	useEffect(() => {
		let supplier_id = getAuthAttribute("supplier_id");
		if (match?.params?.supplier_id) {
			supplier_id = match.params.supplier_id;
		}
		if (supplier_id) {
			loadData(supplier_id);
		} else {
			loadEmployeesData();
		}
		// eslint-disable-next-line
	}, [match]);

	useEffect(() => {
		if (disabled && switchToEditMode) {
			setModel(tempModel.current);
		}
	}, [disabled, switchToEditMode]);

	const loadData = async (supplier_id) => {
		setIsLoading(true);
		const [businessData, profileStatsRes] = await Promise.all([
			getBusinessData(supplier_id),
			getProfileCompletionStatus(supplier_id),
			loadEmployeesData(),
		]);
		setIsLoading(false);
		if (businessData && businessData.code == 200) {
			const res = businessData.result;
			setData(res);
		} else {
			toast.error(businessData && businessData.message);
		}
		if (profileStatsRes && profileStatsRes.code == 200) {
			setProfileStats(profileStatsRes.result);
		} else {
			toast.error(profileStatsRes && profileStatsRes.message);
		}
	};

	const loadEmployeesData = async (supplier_id) => {
		setIsLoading(true);
		const empData = await getEmployees({
			per_page: 99999,
			offset: 0,
			status_id: "1",
		});
		setIsLoading(false);
		if (empData && empData.code == 200) {
			setEmployees([
				...employees,
				...(empData?.result?.data || []).map((r) => ({
					value: r?.emp_id,
					label: `${r?.first_name} ${r?.last_name}`,
				})),
			]);
		} else {
			toast.error(empData?.message);
		}
	};

	const handleTimeChange = (value, key, index) => {
		const updatedWorkingtHours = [...model.working_hours];
		updatedWorkingtHours[index][key] = value;
		setModel({
			...model,
			working_hours: updatedWorkingtHours,
		});
		props.updateIsSavedStatus && props.updateIsSavedStatus();
	};

	const handleToggleDisabled = (index) => {
		const updatedWorkingtHours = [...model.working_hours];
		updatedWorkingtHours[index].isDisabled = !updatedWorkingtHours[index].isDisabled;
		setModel({
			...model,
			working_hours: updatedWorkingtHours,
		});
	};

	const setData = (res) => {
		const working_hours = res.working_hours?.length
			? supplierModel.working_hours.map((item) => {
					const value = res.working_hours.find((value) => value.day === item.day);
					if (value) {
						return {
							...value,
							isDisabled: false,
						};
					} else {
						return {
							...item,
							isDisabled: true,
						};
					}
			  })
			: supplierModel.working_hours;
		let supplierDataModal = {
			logo: res?.logo ? res.logo : model.logo,
			supplier_id: res?.supplier_id,
			supplier_type: res?.type,
			settlement_frequency: res?.settlement_frequency,
			commission: res?.commission,
			supplier_name: res?.en_name,
			arb_supplier_name: res?.arb_name,
			description: res?.en_description,
			arb_description: res?.arb_description,
			registration_number: res?.registration_number || null,
			vat_number: res?.vat_number,
			salon_amt_min: res?.salon_amt_min,
			home_amt_min: res?.home_amt_min,
			payout_threshold: res?.payout_threshold,
			payment_modes: res?.payment_method,
			coming_soon: Number(res?.coming_soon),
			auto_confirm_bookings: res?.auto_confirm_bookings,
			address_id: res?.address_id && res?.address_id.length ? res?.address_id : "",
			location: res?.address?.location,
			supplier_country: res?.supplier_country ?? "",
			supplier_city: res?.supplier_city ?? "",
			neighbourhood: res?.address?.neighbourhood ? res?.address?.neighbourhood : model.neighbourhood,
			lat_lng: res?.address?.lat_lng ? res?.address?.lat_lng : "",
			is_active: Number(res?.status_id) === 2 ? 0 : 1,
			include_in_available_today: res?.include_in_available_today,
			supplier_unqid: res?.supplier_unqid,
			supplier_rep: res?.supplier_rep || null,
			working_hours,
			supplier_gender: res?.supplier_gender || null,
			mobile: res?.mobile ?? null,
		};
		setCurrPhoneNo(res?.mobile ?? null);
		if (res?.address?.location && searchFieldRef.current) {
			searchFieldRef.current.value = res.address.location;
		}
		setComingSoonStatus(supplierDataModal?.coming_soon || 0);
		setModel(supplierDataModal);
		setSelectedCountry(res?.supplier_country ?? null);
		tempModel.current = { ...supplierDataModal };
		switchToEditMode && switchToEditMode();
	};
	useEffect(() => {
		fetchCountryList();
		// eslint-disable-next-line
	}, []);

	const fetchCountryList = async () => {
		toast.dismiss();
		try {
			const result = await getCountryList();
			if (result && result.code == 200) {
				const data = [...countryList];
				result.result.forEach((row) => {
					data.push({
						label: row.name,
						value: row.code,
					});
				});
				setCountryList(data);
			} else {
				throw new Error(result?.message ?? "Something went wrong");
			}
		} catch (error) {
			toast.error(error.message ?? "Something went wrong");
		}
	};

	useEffect(() => {
		if (selectedCountry) {
			fetchCityByCountryCode();
		}
		// eslint-disable-next-line
	}, [selectedCountry]);
	const fetchCityByCountryCode = async () => {
		toast.dismiss();
		try {
			const result = await getCityByCountryCode(selectedCountry);
			if (result && result.code == 200) {
				const data = result.result
					? result.result.map((row) => ({
							label: row.name,
							value: row.city_id,
					  }))
					: [];
				setCityList(data);
			} else {
				throw new Error(result?.message ?? "Something went wrong");
			}
		} catch (error) {
			toast.error(error.message ?? "Something went wrong");
		}
	};

	const handleInputChange = (event) => {
		setModel({
			...model,
			[event.target.name]: event.target.value,
		});
		props.updateIsSavedStatus && props.updateIsSavedStatus();
	};

	const handleDescriptionChange = (event, name) => {
		setModel({
			...model,
			[name]: event.target.value,
		});
		props.updateIsSavedStatus && props.updateIsSavedStatus();
	};

	const handleSupplierTypeChange = (value, name) => {
		setModel({
			...model,
			supplier_type: (value && name) || null,
		});
		props.updateIsSavedStatus && props.updateIsSavedStatus();
	};

	const handleDDChange = (value, name) => {
		setModel({
			...model,
			[name]: value,
		});
		props.updateIsSavedStatus && props.updateIsSavedStatus();
	};

	const handleCountryChange = (value, name) => {
		setModel({
			...model,
			[name]: value.value,
		});
		setSelectedCountry(value.value);
		props.updateIsSavedStatus && props.updateIsSavedStatus();
	};

	const handlePayModeChange = (value) => {
		let payment_modes = model.payment_modes;
		if (payment_modes === "") {
			payment_modes = value;
		} else if (payment_modes.includes(value)) {
			if (payment_modes.includes(",")) {
				payment_modes = payment_modes.replace(",", "");
			}
			payment_modes = payment_modes.replace(value, "");
		} else {
			payment_modes = payment_modes + "," + value;
		}
		setModel({
			...model,
			payment_modes,
		});
		props.updateIsSavedStatus && props.updateIsSavedStatus();
	};

	const handleCBModeChange = (value, name) => {
		if (name === "include_in_available_today") {
			if (model.include_in_available_today === "1") {
				value = "0";
			} else if (model.include_in_available_today === "0") {
				value = "1";
			}
		}
		setModel({
			...model,
			[name]: value,
		});
		props.updateIsSavedStatus && props.updateIsSavedStatus();
	};

	const handleGoLive = async (event) => {
		if (event.target.id === "coming_soon") {
			setComingSoonStatus(1);
			props.updateIsSavedStatus && props.updateIsSavedStatus();
		} else {
			setIsLoading(true);
			const res = await getProfileCompletionStatus(model?.supplier_id);
			setIsLoading(false);
			if (res.code < 400) {
				if (res?.result?.is_profile_complete) {
					setComingSoonStatus(0);
				} else {
					toast.error("Incomplete Profile, please complete to go Live");
					return;
				}
				props.updateIsSavedStatus && props.updateIsSavedStatus();
			} else {
				toast.error(res.message || "Something went wrong.Please try again");
			}
		}
	};

	const onImageSelect = async (event) => {
		const image = event.target.files[0];
		if (image) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				setImagePreviewURL({
					image: image,
					src: reader.result,
				});
				setModel({
					...model,
					logo: reader?.result,
				});
			});
			reader.readAsDataURL(image);
		}
	};

	const validateCords = (params) => {
		const cords = params?.split(",") ?? [];
		if (_.isEmpty(cords) || cords?.length !== 2) {
			return false;
		} else if (cords?.length === 2 && (_.isEmpty(cords[0]) || _.isEmpty(cords[1]))) {
			return false;
		}
		return true;
	};

	const handleSaveSupplier = async () => {
		toast.dismiss();
		if (simpleValidator.current.allValid()) {
			const working_hours = model.working_hours
				.filter((item) => item.isDisabled != true)
				.map((item) => ({
					day: item.day,
					start_time: item.start_time,
					end_time: item.end_time,
				}));
			const data = {
				...model,
				registration_number: model?.registration_number || "",
				supplier_id: model.supplier_id ? model.supplier_id : "0",
				supplier_status_id: model.supplier_status_id ? model.supplier_status_id : "",
				working_hours,
				coming_soon: comingSoonStatus,
			};
			const isValidCords = validateCords(data?.lat_lng ?? []);
			if (!isValidCords) {
				toast.error(t("Please select valid location"));
				return;
			}

			if (imagePreviewURL.image) {
				const imageData = new FormData();
				imageData.append("file", imagePreviewURL.image);
				const imageUrl = await uploadImage(imageData);
				const logo_filename = imageUrl.result.file_name;
				data["logo_filename"] = logo_filename;
			}

			if (userTypeId == 2) {
				if (currPhoneNo && currPhoneNo !== data?.mobile && !isNewPhoneVerified) {
					setShowPartnerMobileModal(data?.mobile);
					return;
				}
			}

			setIsLoading(true);
			const result = await saveSupplier(data);
			setIsLoading(false);
			if (result && result.code == 200) {
				setUpdateProfileStatus && setUpdateProfileStatus(true);
				setAuthAttribute("supplier_name", model.supplier_name);
				toast.success("Saved Successfully");
				props?.onClose && props.onClose(true);
				tempModel.current = { ...model };
				toggleDisabled && toggleDisabled(true);
			} else {
				toast.error((result && result.message) || "Something wrong");
			}
		} else {
			simpleValidator.current.showMessages();
			forceUpdate(1);
			toast.error("Please enter valid details");
		}
	};

	const getPlaceValue = (lat, lng) => {
		return new Promise((resolve, reject) => {
			fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDfxpYsb_0sfaP5dQ9Lonpd4staJ4ErH6I`)
				.then((response) => {
					return response.json();
				})
				.then((result) => {
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const handlePhoneNumberChange = (value) => {
		if (value.countryCode) {
			setModel({
				...model,
				mobile: `${value.countryCode}-${value.phoneNumber}`,
			});
		} else {
			setModel({
				...model,
				mobile: null,
			});
		}

		props.updateIsSavedStatus && props.updateIsSavedStatus();
	};

	const handleMarkerDrag = async (e) => {
		const lat = e.latLng.lat();
		const lng = e.latLng.lng();
		try {
			const addresses = await getPlaceValue(lat, lng);
			if (addresses?.results?.length) {
				const filteredAddresses = (addresses.results || []).filter((item) => item.types.includes("political") && item.types.includes("sublocality"));
				const location = addresses.results[0].formatted_address;
				const neighbourhood = filteredAddresses[0].address_components[0].long_name;
				searchFieldRef.current.value = location;
				if (!lat || !lng) {
					toast.error("Please enter valid address");
					return;
				}
				setModel({
					...model,
					location,
					neighbourhood,
					lat_lng: [lat, lng].toString(),
				});
			}
		} catch (error) {
			toast.error(error.message || "Something went wrong");
		}
		setMarkerCords({
			lat,
			lng,
		});
	};

	simpleValidator.current.purgeFields();

	const handleShareLink = async (link) => {
		const theClipboard = navigator.clipboard;
		await theClipboard.writeText(link);
		toast.dismiss();
		toast.success("Link copied to the Clipboard");
	};

	const subtitle = useMemo(() => {
		if (!model.supplier_unqid) {
			return null;
		}
		const link =
			window.location.hostname === "partners.boon.sa"
				? `https://boon.sa/p/${model.supplier_unqid}`
				: `https://alpha.boon.sa/p/${model.supplier_unqid}`;
		return (
			<>
				{/* <div
          className="copy-link d-flex justify-content-end align-items-center"
          onClick={() => handleShareLink(link)}
        >
          <span className="">{link}</span>
          <Button>{t("Copy Link")}</Button>
        </div> */}
				<Container className="copy-link">
					<Row className="d-flex justify-content-end">
						<Col md="6" xl="6" sm="6">
							<div className="input-group mb-3">
								<input type="text" value={link} className="form-control" />
								<span onClick={() => handleShareLink(link)} className="btn">
									{t("Copy Link")}
								</span>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		);
	}, [model.supplier_unqid, t]);

	const onMapLoad = (map) => {
		mapRef.current = map;
	};

	return (
		<Row>
			<Container className="my-business">
				{(isLoading && <FullPageSpinner />) || null}
				{hideTitle || isUserAdmin ? null : (
					<Row className="heading">
						<Col md="5" className="title">
							{t("Add My Business")}
						</Col>
						<Col md="7">
							<div className="float-right">
								<img src={CancelIcon} className="cancel" alt="cross_icon" onClick={() => history.push("/my-businesses")} />
							</div>
						</Col>
					</Row>
				)}
				<div className="my-business-form">
					<div className="warning mb-4">{warningMessage && warningMessage(profileStats)}</div>
					<Panel subtitle={subtitle}>
						<Container fluid className="basic-info">
							<Row className="mb">
								<Col md="6 pl-5 mb-3">
									<label className="profile-pic-label">
										<img
											src={model?.logo || imagePreviewURL?.src}
											// onError={handleImageError}
											className="profile-pic"
											alt="staff-member-pi"
										/>
										<div className="overlay"></div>
										<input
											disabled={disabled}
											name='"profile-input'
											visibility="hidden"
											type="file"
											id="profile-input"
											accept=".png, .jpg, .jpeg"
											onChange={onImageSelect}
										/>
									</label>
								</Col>
								<Col md="6" className="checkbox-col">
									<div className="w-100">
										<div style={{ width: "fit-content" }}>
											{((userTypeId == 4 || userTypeId == 5) && (
												<div className="checkbox-block">
													<Form.Label>
														{t("Type")}
														<span style={{ color: "#943434" }}>*</span>&nbsp;:
													</Form.Label>
													<div className="checkbox-wrapper">
														<div onClick={() => handleSupplierTypeChange(true, "salon")} className="checkbox-label">
															<CheckBoxInput
																disabled={disabled}
																checked={model.supplier_type === "salon" || false}
																onChange={(event) => handleSupplierTypeChange(true, "salon")}
															>
																{t("Salon")}
															</CheckBoxInput>
														</div>
														<div onClick={() => handleSupplierTypeChange(true, "freelancer")} className="checkbox-label">
															<CheckBoxInput
																disabled={disabled}
																checked={model.supplier_type === "freelancer" || false}
																onChange={(event) => handleSupplierTypeChange(true, "freelancer")}
															>
																{t("Freelancer")}
															</CheckBoxInput>
														</div>
													</div>
													{simpleValidator.current.message("supplier_type", model && model.supplier_type, "required")}
												</div>
											)) || (
												<p>
													<b>
														{t("Business Type")} : {model?.supplier_type?.toUpperCase()}
													</b>
												</p>
											)}
										</div>
										{(model?.supplier_id && (
											<div className="">
												<div className="checkbox-block mt-3">
													<InputGroup>
														<Form.Label>{t("Your Status")}</Form.Label>
														<Form.Check
															inline
															label={t("Coming Soon")}
															name="group1"
															checked={comingSoonStatus === 1}
															type={"radio"}
															id="coming_soon"
															onChange={handleGoLive}
															disabled={disabled || model.coming_soon == 0}
														/>
														<Form.Check
															inline
															label={t("Go Live")}
															name="group1"
															checked={comingSoonStatus === 0}
															type={"radio"}
															id="go_live"
															onChange={handleGoLive}
															disabled={disabled || model.coming_soon == 0}
														/>
													</InputGroup>
												</div>
												{((userTypeId == 4 || userTypeId == 5) && (
													<div className="checkbox-block mt-3">
														<InputGroup>
															<Form.Label>{t("Account Status")}</Form.Label>
															<Form.Check
																inline
																label={t("Active")}
																name="account_status_group"
																checked={model.is_active === 1 ? true : false}
																type={"radio"}
																id="active"
																onChange={(event) => handleCBModeChange(model.is_active === 1 ? 0 : 1, "is_active")}
																disabled={disabled}
															/>
															<Form.Check
																inline
																label={t("In Active")}
																name="account_status_group"
																checked={model.is_active === 0 ? true : false}
																type={"radio"}
																id="in_active"
																onChange={(event) => handleCBModeChange(model.is_active === 1 ? 0 : 1, "is_active")}
																disabled={disabled}
															/>
														</InputGroup>
													</div>
												)) ||
													null}
											</div>
										)) ||
											null}
									</div>
								</Col>
								<Col md="6">
									<UserInput
										required
										label={t("Business Name(eng)")}
										disabled={disabled}
										placeholder={t("Business Name(eng)")}
										name="supplier_name"
										value={model?.supplier_id && model.supplier_name}
										onChange={(event) => handleInputChange(event, "supplier_name")}
									/>
									{simpleValidator.current.message("supplier_name", model && model.supplier_name, "required")}
								</Col>
								<Col md="6">
									<UserInput
										required
										disabled={disabled}
										label={t("Business Name(arb)")}
										placeholder={t("Business Name(arb)")}
										name="arb_supplier_name"
										value={model.arb_supplier_name}
										onChange={(event) => handleInputChange(event, "arb_supplier_name")}
									/>
									{simpleValidator.current.message("arb_supplier_name", model && model.arb_supplier_name, "required")}
								</Col>
								<Col md="6">
									<PhoneNumberInput
										disabled={disabled}
										required
										label={t("Phone Number")}
										placeholder="5xxxxxxx"
										selectedValues={(values) => handlePhoneNumberChange(values)}
										value={model.mobile}
										maxLength="9"
									/>
									{simpleValidator.current.message("mobile", model?.mobile, "required")}
								</Col>
								{(isUserAdmin && (
									<>
										{model.supplier_type === "salon" && (
											<Col md="6">
												<UserInput
													required={isUserAdmin}
													label={t("VAT Number")}
													placeholder={t("VAT Number")}
													name="vat_number"
													disabled={!isUserAdmin || disabled}
													value={model.vat_number}
													onChange={(event) => handleInputChange(event, "vat_number")}
												/>
												{isUserAdmin && simpleValidator.current.message("vat_number", model && model.vat_number, "required")}
											</Col>
										)}
										{(model.supplier_type === "salon" && (
											<Col md="6">
												<UserInput
													required
													label={t("Registration Number")}
													placeholder={t("Registration Number")}
													name="registration_number"
													disabled={!isUserAdmin || disabled}
													value={model.registration_number}
													onChange={(event) => handleInputChange(event, "registration_number")}
												/>
												{simpleValidator.current.message("registration_number", model && model.registration_number, "required")}
											</Col>
										)) ||
											null}
										<Col md="6">
											<UserInput
												required
												label={t("Payout Threshold (SAR)")}
												placeholder={t("Payout Threshold (SAR)")}
												disabled={!isUserAdmin || disabled}
												name="payout_threshold"
												value={model.payout_threshold}
												onChange={(event) => handleInputChange(event, "payout_threshold")}
											/>
											{simpleValidator.current.message("payout_threshold", model && model.payout_threshold, "required|number")}
										</Col>
										<Col md="6">
											<CustomDropdown
												required
												onChange={(value) => handleDDChange(value.value, "commission")}
												disabled={!isUserAdmin || disabled}
												name="commission"
												options={commissionOptions}
												label={t("Boon Commission(%)")}
												value={commissionOptions.find((row) => row.value === (model ? +model.commission : "")) || ""}
											/>
										</Col>
									</>
								)) ||
									null}

								<Col md="6">
									<UserInput
										required
										label={t("Home Amount Min (SAR)")}
										placeholder={t("Home Amount Min (SAR)")}
										disabled={disabled}
										name="home_amt_min"
										value={model.home_amt_min}
										onChange={(event) => handleInputChange(event, "home_amt_min")}
									/>
									{simpleValidator.current.message("home_amt_min", model && model.home_amt_min, "required|number")}
								</Col>
								<Col md="6">
									<UserInput
										required
										label={t("Salon Amount Min (SAR)")}
										placeholder={t("Salon Amount Min (SAR)")}
										disabled={disabled}
										name="salon_amt_min"
										value={model.salon_amt_min}
										onChange={(event) => handleInputChange(event, "salon_amt_min")}
									/>
									{simpleValidator.current.message("salon_amt_min", model && model.salon_amt_min, "required|number")}
								</Col>
								<Col md="6">
									<CustomDropdown
										required
										onChange={(value) => handleDDChange(value.value, "settlement_frequency")}
										disabled={disabled | (userTypeId != 4 && userTypeId != 5) || false}
										name="settlement_frequency"
										options={settelmentOptions}
										label={t("Settlement Frequency")}
										value={settelmentOptions.find((row) => row.label === (model && model.settlement_frequency)) || ""}
									/>
									{simpleValidator.current.message("settlement_frequency", model && model.settlement_frequency, "required")}
								</Col>
								<Col md="6">
									<CustomDropdown
										onChange={(value) => handleDDChange(value.value, "supplier_gender")}
										disabled={disabled}
										name="supplier_gender"
										options={supplierGenderOptions}
										label={t("Supplier Availability(Gender(s))")}
										value={supplierGenderOptions.find((row) => row.value === (model && model.supplier_gender)) || ""}
									/>
								</Col>
								<Col md="6">
									<CustomDropdown
										onChange={(value) => handleDDChange(value.value, "auto_confirm_bookings")}
										disabled={disabled}
										name="auto_confirm_bookings"
										options={autoConfirmBookings}
										label={t("Auto Confirm Bookings")}
										value={autoConfirmBookings.find((row) => row.value === (model && model.auto_confirm_bookings)) || ""}
									/>
								</Col>
								{(isUserAdmin && (
									<Col md="6">
										<CustomDropdown
											onChange={(value) => handleDDChange(value.value, "supplier_rep")}
											disabled={!isUserAdmin || disabled}
											name="supplier_rep"
											options={employees || []}
											label={t("Partner Representative")}
											value={employees.find((row) => row.value == (model ? +model.supplier_rep : "")) || ""}
										/>
									</Col>
								)) ||
									null}
								<Col md="6" className="checkbox-col">
									<div className="checkbox-block">
										<Form.Label>{`${t("Payment Modes")} :`}</Form.Label>
										<div className="checkbox-wrapper">
											<div onClick={() => handlePayModeChange("1")} className="checkbox-label">
												<CheckBoxInput
													disabled={disabled}
													checked={model?.payment_modes ? model?.payment_modes.includes("1") : false}
													onChange={(event) => handlePayModeChange("1")}
												>
													{t("Card")}
												</CheckBoxInput>
											</div>
											<div onClick={() => handlePayModeChange("2")} className="checkbox-label">
												<CheckBoxInput
													disabled={disabled}
													checked={model?.payment_modes ? model?.payment_modes.includes("2") : false}
													onChange={(event) => handlePayModeChange("2")}
												>
													{t("Cash")}
												</CheckBoxInput>
											</div>
										</div>
									</div>
								</Col>
								<Col md="6" className="checkbox-col">
									<div className="checkbox-block">
										<Form.Label>{`${t("Available Today")} :`}</Form.Label>
										<div className="checkbox-wrapper">
											<div className="checkbox-label">
												<CheckBoxInput
													disabled={disabled}
													checked={model?.include_in_available_today === "1" || false}
													onChange={(event) => handleCBModeChange(0, "include_in_available_today")}
												></CheckBoxInput>
											</div>
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md="12">
									<CustomTextArea
										disabled={disabled}
										label={t("Description(eng) (Optional)")}
										name="description"
										value={model.description}
										onChange={(event) => handleDescriptionChange(event, "description")}
									/>
									{/* {simpleValidator.current.message(
                    "eng_description",
                    model && model.description,
                    "required"
                  )} */}
								</Col>
							</Row>
							<Row>
								<Col md="12" className="mt-4">
									<CustomTextArea
										disabled={disabled}
										label={t("Description(arb) (Optional)")}
										name="arb_description"
										value={model.arb_description}
										onChange={(event) => handleDescriptionChange(event, "arb_description")}
									/>
									{/* {simpleValidator.current.message(
                    "arb_description",
                    model && model.arb_description,
                    "required"
                  )} */}
								</Col>
							</Row>
						</Container>
					</Panel>
					<Panel title={t("Address")}>
						<Container fluid className="address-container">
							<Row>
								<Col>
									<div>
										<LoadScript id="script-loader" googleMapsApiKey="AIzaSyDfxpYsb_0sfaP5dQ9Lonpd4staJ4ErH6I" libraries={["places", "drawing"]}>
											<GoogleMap
												id="map_canvas"
												mapContainerStyle={{
													width: "100%",
													height: "400px",
												}}
												center={centerCords}
												zoom={15}
												onLoad={onMapLoad}
											>
												<Marker position={markerCords} onDragEnd={handleMarkerDrag} title={t("Your current location")} draggable={!disabled} />
												<MapSearch ref={searchFieldRef} isDisabled={disabled} handleCenterChange={onPlacesChanged} />
											</GoogleMap>
										</LoadScript>
									</div>
								</Col>
							</Row>
							<Row className="mt-3 mb">
								<Col md="6">
									<UserInput
										label={t("Neighborhood")}
										placeholder={t("Neighborhood")}
										name="Neighborhood"
										value={model.neighbourhood}
										disabled={true}
									/>
								</Col>
								<Col md="6">
									<UserInput
										disabled={disabled}
										required
										label={t("Location Name")}
										placeholder={t("Location Name")}
										name="location"
										value={model.location}
										onChange={(event) => handleInputChange(event, "location")}
									/>
									{simpleValidator.current.message("location", model && model.location, "required")}
								</Col>
								<Col md="6">
									<SearchableDropdown
										required
										onChange={(value) => handleCountryChange(value, "supplier_country")}
										disabled={disabled}
										label={t("Country")}
										placeholder={t("Select Country")}
										name="country"
										options={countryList}
										value={countryList.find((value) => value.value === model.supplier_country || "")}
									/>
									{simpleValidator.current.message("country", model && model.supplier_country, "required")}
								</Col>
								<Col md="6">
									<SearchableDropdown
										required
										onChange={(value) => handleDDChange(value.value, "supplier_city")}
										disabled={disabled}
										label={t("City")}
										placeholder={t("Select City")}
										name="supplier_city"
										options={cityList}
										value={cityList.find((value) => value.value === model.supplier_city || "")}
									/>
									{simpleValidator.current.message("city", model && model.supplier_city, "required")}
								</Col>
							</Row>
						</Container>
					</Panel>
					<Panel title={t("Working Hours")}>
						<Container fluid className="working-hour">
							<Col md="12">
								<Row className="row-1">
									<Col md="2">{t("Day")}</Col>
									<Col md="4">
										{t("Start Time")}
										<span style={{ color: "#943434" }}>*</span>
									</Col>
									<Col md="4">
										{t("End Time")}
										<span style={{ color: "#943434" }}>*</span>
									</Col>
									<Col md="2">{t("Day Off")}</Col>
								</Row>
								{model?.working_hours &&
									model?.working_hours.map((value, index) => {
										const workingHours = { ...model.working_hours[index] };
										const startTimeValue = workingHours.start_time;
										const endTimeValue = workingHours.end_time;
										const isDisabled = workingHours.isDisabled;
										return (
											<Row className="day-row" key={value.day}>
												<Col md="2">{t(`${value.day}`)}</Col>
												<Col md="4">
													<SearchableDropdown
														onChange={(value) => handleTimeChange(value.value, "start_time", index)}
														disabled={isDisabled || disabled}
														placeholder={t("Select Time")}
														name="start_time"
														options={dateOptions}
														value={dateOptions.find((value) => value.value === startTimeValue)}
													/>
													{isDisabled ? null : simpleValidator.current.message(`${value.day}'s start time`, startTimeValue, "required")}
												</Col>
												<Col md="4">
													<SearchableDropdown
														onChange={(value) => handleTimeChange(value.value, "end_time", index)}
														disabled={isDisabled || disabled}
														placeholder={t("Select Time")}
														name="end_time"
														options={dateOptions}
														value={dateOptions.find((value) => value.value === endTimeValue)}
													/>
													{isDisabled
														? null
														: simpleValidator.current.message(
																`${value.day}'s end time`,
																endTimeValue,
																`required|time:${startTimeValue ? startTimeValue?.replace(":", " ") : ""}`
														  )}
												</Col>
												<Col md="2">
													<CheckBoxInput
														disabled={disabled}
														classNames={"day-off-checbox"}
														checked={isDisabled}
														onChange={() => handleToggleDisabled(index)}
													/>
												</Col>
											</Row>
										);
									})}
							</Col>
						</Container>
					</Panel>
					{!disabled && (
						<Container fluid className="services mb-5 pb-5">
							<Row>
								<Col md="6" className="pl-0">
									{(checkActionPermission("my_businesses", "canAdd") && <PrimaryButton text={t("Save")} onClick={() => handleSaveSupplier()} />) ||
										null}
								</Col>
							</Row>
						</Container>
					)}
				</div>
				<ToastContainer
					position="bottom-center"
					autoClose={5000}
					hideProgressBar={true}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Container>

			{showPartnerMobileModal && (
				<VerifyMobileNumber
					mobile={showPartnerMobileModal}
					onClose={(value) => {
						if (value) {
							setIsNewPhoneVerified(true);
						} else {
							setIsNewPhoneVerified(false);
						}
						setShowPartnerMobileModal(null);
					}}
				/>
			)}
		</Row>
	);
};
export default AddMyBusiness;
